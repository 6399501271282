import { Empty, message, Timeline } from 'antd'
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { getWorklogProblems } from '../../service'
import { WorklogProblem } from '../../Model'

interface Params {
    problemNumber: string
}

type Props = Params

const DisplayWorkLog = (props: Props) => {
    const [problemWorklog, setProblemWorkLog] = useState<WorklogProblem[]>()

    useEffect(() => {
        fetchWorkLogData()
    }, [])

    const fetchWorkLogData = () => {
        getWorklogProblems(props.problemNumber)
            .then((data) => {
                setProblemWorkLog(data)
            })
            .catch((err) =>
                message.error(`Failed loading initial Problems data. ${err}`)
            )
    }

    const displayWorkLog = () => {
        const workLogElements: JSX.Element[] = []
        // eslint-disable-next-line react/prop-types
        if (problemWorklog) {
            for (const [index, workLog] of problemWorklog.entries()) {
                if (workLog) {
                    workLogElements.push(
                        <Timeline.Item color={index === problemWorklog.length - 1 ? 'green' : 'blue'}>
                            <p style={{ fontSize: '16px', color: '#323276', fontWeight: 'bold'}}>
                                {moment(workLog.createdDatetime).format('YYYY-MM-DD HH:mm:ss')}
                            </p>
                            <p>{` ${workLog.messageLog}`}</p>
                        </Timeline.Item>
                    )
                }
            }
        }
        return (
            <>
                <div
                    style={{
                        height: 590,
                        width: '100%',
                        backgroundColor: '#ffffff',
                        overflowY: 'scroll',
                        padding: 10
                    }}
                >
                    {workLogElements.length !== 0 ? (
                        <Timeline style={{ marginLeft: '40px', marginTop: '10px' }}>
                            {workLogElements.map((temps: any, i: number) => {
                                return <div key={i}>{temps}</div>
                            })}
                        </Timeline>
                    ) : (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )}
                </div>
            </>
        )
    }

    return (
        <div>
            <div>{displayWorkLog()}</div>
        </div>
    )
}

export default DisplayWorkLog
