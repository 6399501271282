/* eslint-disable camelcase */
import { Modal, Tabs } from 'antd'
import React, { useState } from 'react'
import { getCookie } from '../../../../guard/route'
import jwt_decode from 'jwt-decode'
import SelectedDataRelatedProblem from './SelectedDataRelatedProblem'
import SelectRelateDataTableProblems from './SelectRelateDataTableProblems'

interface Param {
    ticketTypeIsRequest?: boolean
    isShowOnlyTabKM: boolean
}

type Props = Param

const { TabPane } = Tabs
const { confirm } = Modal
const RelatedProblemHome: React.FC<Props> = (props: Props) => {
    const [isModalVissible, setIsModalVisible] = useState<boolean>(false)
    const [tabpaneSelectName, setTabpaneSelectName] = useState<string>('Ticket')
    const [isCancelModal, setIsCancelModal] = useState<boolean>(false)
    const decoded: any = jwt_decode(getCookie('access_token') || '')

    const ButtonSelectdata = (tabpane: string) => {
        return (
            <div style={{ textAlign: 'end' }}>
                <span className="feck-link" onClick={() => { showModal(tabpane) }} style={{fontSize: '16px', fontWeight: 'bold'}}>Select {tabpane}</span>
            </div>
        )
    }

    const showModal = (value: string) => {
        setIsModalVisible(true)
        setTabpaneSelectName(value)
    }

    const closeModal = () => {
        confirm({
            title: 'Are you sure ?',
            content: 'You want to leave this page ?',
            onOk() {
                setIsModalVisible(false)
                setIsCancelModal(true)
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    return (
        <div>
            {props.isShowOnlyTabKM ? <Tabs defaultActiveKey="2" type="card">
                <TabPane tab="Knowledge" key="2" disabled={!decoded.service.serviceId.includes(5)}>
                    {ButtonSelectdata('Knowledge')}
                    <br />
                    <SelectedDataRelatedProblem tabpaneName={'Knowledge'} isShowOnlyTabKM={props.isShowOnlyTabKM}/>
                </TabPane>
            </Tabs> : <Tabs defaultActiveKey="1" type="card">
                <TabPane tab="Asset" key="1" disabled={!decoded.service.serviceId.includes(2)}>
                    {ButtonSelectdata('Asset')}
                    <br />
                    <SelectedDataRelatedProblem tabpaneName={'Asset'} />
                </TabPane>
                <TabPane tab="Knowledge" key="2" disabled={!decoded.service.serviceId.includes(5)}>
                    {ButtonSelectdata('Knowledge')}
                    <br />
                    <SelectedDataRelatedProblem tabpaneName={'Knowledge'} />
                </TabPane>
                <TabPane tab="Ticket" key="3" disabled={!decoded.service.serviceId.includes(1)}>
                    {ButtonSelectdata('Ticket')}
                    <br />
                    <SelectedDataRelatedProblem tabpaneName={'Ticket'} />
                </TabPane>
            </Tabs>}
            <Modal
                title={tabpaneSelectName === 'Workflow' ? tabpaneSelectName : tabpaneSelectName + ' List'}
                visible={isModalVissible}
                onCancel={() => closeModal()}
                closable={true}
                footer={null}
                width={'90%'}
            >
                <SelectRelateDataTableProblems modalVisisle={isModalVissible} setModalvisible={setIsModalVisible} tabpaneName={tabpaneSelectName} isCancelModal={isCancelModal} setIsCancelModal={setIsCancelModal} />
            </Modal>
        </div>
    )
}

export default RelatedProblemHome
