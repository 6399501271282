import { Row, Form, Button, Input, Comment } from 'antd'
import React, { useEffect, useState } from 'react'
import AutoAvatar from '../../../../common-components/autoAvatar'
import { UserProfile } from '../../../../authorization-module/user-role/duck/model'
import { FormComponentProps } from 'antd/lib/form'
import { createWorkNoteReply } from '../../service'
import { Reply, WorkNoteProblems } from '../../Model'
import { getUserProfile } from '../../../../authorization-module/user-role/duck/action'
import { checkRolesState, RoleType } from '../../../../authorization-module/permissions'

const { TextArea } = Input

interface Params {
    id: number
    data: WorkNoteProblems
    getData: Function
}

type Props = FormComponentProps & Params
const CommentListProblem = (props: Props) => {
    const [comments, setComments] = useState<Reply[]>(props.data?.reply || [])
    const [submitting, setSubmitting] = useState<boolean>(false)
    const [commentsValue, setCommentsValue] = useState<string>()
    const username = sessionStorage.getItem('username')
    const [dataUser, setDataUser] = useState<UserProfile>()
    const { getFieldDecorator } = props.form
    const isRenderPTask = window.location.pathname.includes('/ProblemTaskDetailView')

    useEffect(() => {
        getUserProfile().then((res) => {
            if (res) {
                setDataUser(res)
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // const CommentList = () => (
    //     <List
    //         dataSource={comments}
    //         header={`${comments.length} ${comments.length > 1 ? 'comments' : 'comment'}`}
    //         itemLayout="horizontal"
    //         // eslint-disable-next-line react/prop-types
    //         renderItem={(props: any) => <Row>
    //             <Comment
    //                 datetime={<div style={{ color: 'rgba(0, 0, 0, 0.45)' }}>{moment(props?.createdDatetime).fromNow()} <span>( {props?.createdDatetime} )</span> </div>}
    //                 author={<div className='feck-link'><b>{props.createdBy?.fullName?.toString()}</b></div>}
    //                 {...props}
    //                 avatar={<Tooltip title={props.replyByTooltip}><AutoAvatar title={props.createdBy?.pictureProfile ? JSON.parse(props.createdBy?.pictureProfile)[0].url : props.createdBy.email?.toString()} broken={false} /></Tooltip>}
    //             />
    //         </Row>}
    //     />
    // )

    const handleChange = (e: any) => {
        setCommentsValue(e.target.value)
    }

    const handleSubmit = () => {
        if (!commentsValue || commentsValue.length < 3) {
            return
        }
        const commentBody:any = {
            content: commentsValue.trim()
        }
        if (isRenderPTask) {
            commentBody.ptaskWorknoteId = props.id
        } else {
            commentBody.pbWorknoteId = props.id
        }
        if (commentBody.content.trim() !== '') {
            setSubmitting(true)
            createWorkNoteReply(commentBody, isRenderPTask).then((data) => {
                const convertData = {
                    ...data,
                    lastModifiedBy: username || '',
                    createdBy: {
                        ...dataUser
                    }
                }
                props.getData(convertData)
                setComments([
                    ...comments,
                    convertData
                ])
            }).finally(() => {
                props.form.setFieldsValue({
                    comment: undefined
                })
                setSubmitting(false)
                setCommentsValue('')
            })
        }
    }

    return (
        <div>
            <Row>
                {/* {comments.length > 0 && <CommentList />} */}
                <Comment
                    avatar={
                        <AutoAvatar title={dataUser?.pictureProfile ? JSON.parse(dataUser?.pictureProfile)[0].url : sessionStorage.getItem('username') || ''} broken={false} username={sessionStorage.getItem('username')?.toString() || ''} />
                    }
                    content={
                        <div>
                            <Form.Item>
                                {getFieldDecorator('comment',
                                    {
                                        rules: [{
                                            required: false
                                        }, { min: 3, message: 'Enter more than 3 characters' }, { whitespace: true, message: ' Reply can not be empty' }
                                        ]
                                    })(
                                    <Input onChange={handleChange} placeholder='Reply' />
                                    // <TextArea rows={1} autoSize={{ minRows: 1, maxRows: 1 }}  value={commentsValue} /> ทราย เปลี่ยนจาก TextArea เป็น input ธรรมดา
                                )}

                            </Form.Item>
                            <Form.Item style={{ textAlign: 'end' }}>
                                <Button htmlType="submit" loading={submitting} onClick={handleSubmit} type="primary" disabled={!checkRolesState(RoleType.Problem, 'UpdateProblem')}>
                                    Reply
                                </Button>
                            </Form.Item>
                        </div>
                    }
                />
            </Row>
        </div>
    )
}

const CommentListProblemFrom = Form.create<Props>()(CommentListProblem)
export default CommentListProblemFrom
