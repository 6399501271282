/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { BrowserMultiFormatReader, BarcodeFormat, DecodeHintType } from '@zxing/library'
import { Select } from 'antd'

interface Param {
    getDataBarcodeScan?: Function
}

type Props = Param

const { Option } = Select
const formats = [
    BarcodeFormat.AZTEC,
    BarcodeFormat.CODABAR,
    BarcodeFormat.CODE_39,
    BarcodeFormat.CODE_128,
    BarcodeFormat.EAN_8,
    BarcodeFormat.EAN_13,
    BarcodeFormat.ITF,
    BarcodeFormat.PDF_417,
    BarcodeFormat.QR_CODE,
    BarcodeFormat.RSS_14,
    BarcodeFormat.DATA_MATRIX
]
const hints = new Map()
hints.set(DecodeHintType.POSSIBLE_FORMATS, formats)
hints.set(DecodeHintType.TRY_HARDER, true)
hints.set(DecodeHintType.ASSUME_GS1, true)
const codeReader = new BrowserMultiFormatReader(hints)
const BarcodeScanner: React.FC<Props> = (props: Props) => {
    const [chooseCamera, setChooseCamera] = useState<string>('')
    const [listCamera, setListCamera] = useState<MediaDeviceInfo[]>([])
    const [screen, setScreen] = useState(window.innerWidth)
    const [width, setWidth] = useState<any>(500)
    let firstDeviceId = ''

    useEffect(() => {
        codeReader
            .listVideoInputDevices()
            .then(videoInputDevices => {
                setListCamera(videoInputDevices)
                videoInputDevices.forEach(() => {
                    firstDeviceId = videoInputDevices[videoInputDevices.length - 1].deviceId
                    const videoConstraints = {
                        video: {
                            deviceId: { exact: firstDeviceId },
                            facingMode: 'environment',
                            width: { ideal: 1920 },
                            height: { ideal: 1080 },
                            frameRate: { ideal: 60, max: 120 }
                        }
                    }

                    navigator.mediaDevices.getUserMedia(videoConstraints).then((stream) => {
                        const videoElement = document.getElementById('video') as HTMLVideoElement
                        if (videoElement) {
                            videoElement.srcObject = stream
                            videoElement.play()
                        }
                        codeReader
                        .decodeOnceFromVideoDevice(firstDeviceId, videoElement)
                        .then(result => {
                            if (props.getDataBarcodeScan && result !== null) {
                                props.getDataBarcodeScan(result.getText())
                                console.log(result.getText())
                                codeReader.reset()
                            }
                        })
                        .catch(() => null)
                    })
                    
                }
                )
            })
            .catch(err => console.error(err))
    }, [])

    useEffect(() => {
        codeReader
            .listVideoInputDevices()
            .then(videoInputDevices => {
                videoInputDevices.forEach(() => {
                    codeReader
                        .decodeOnceFromVideoDevice(chooseCamera, 'video')
                        .then(result => {
                            if (props.getDataBarcodeScan && result !== null) {
                                props.getDataBarcodeScan(result.getText())
                                console.log(result.getText())
                                codeReader.reset()
                            }
                        })
                        .catch(() => null)
                }
                )
            })
            .catch(err => console.error(err))
    }, [chooseCamera, props])

    useEffect(() => {
        setScreen(window.innerWidth)
        if (screen > 560) {
            setWidth(500)
        } else if (screen > 500) {
            setWidth(450)
        } else if (screen > 450) {
            setWidth(410)
        } else if (screen > 350) {
            setWidth(320)
        } else if (screen > 300) {
            setWidth(250)
        }
    }, [screen])

    const handleChange = (value: any) => {
        setChooseCamera(value)
    }

    return (
        <div>
            Choose Camera : <Select placeholder="-- Select a Camera --" defaultValue={firstDeviceId || undefined} style={{ width: 200 }} onChange={handleChange}>
                {listCamera.map((it, index) => {
                    return <Option value={it.deviceId} key={index}>{it.label}</Option>
                })}
            </Select>
            <br /><br />

            <video
                id="video"
                width={width}
                height={width}
                style={{ border: '1px solid gray' }}
            >
            </video>
        </div>
    )
}

export default BarcodeScanner
