import {FieldDetail} from '../../incident-management/incident'

import {axiosDelete, axiosGet, axiosPatch, axiosPost, axiosPostWithPagination} from '../rest'
import {
    AssignmentGroups,
    MyFilter,
    predefinedInfo,
    sentMailWorkOrder,
    SupportTeamList,
    WorkOrder,
    WorkOrderList
} from './model'
import {
    DeleteWorkOrderSuccessEvent,
    GetAllWorkOrderSuccessEvent,
    UpdateTotalContentWorkOrderEvent,
    UpdateWorkOrderPageEvent,
    UpdateWorkOrderSuccessEvent
} from './state-event'

import {Dispatch} from 'redux'
import {checkRedirect, herderPostPatch} from '../../common-misc'
import {decryptBody, encryptBody} from '../../common-misc/encryptBody'
import {CustomFiled} from '../../configurable-fields'
import moment from 'moment'
import {ApproveWorkOrder} from '../../knowledge-management/approval/model'
import {jobSignatureDetail} from '../Relate/Job'

export async function getWorkOrderById(id: string): Promise<WorkOrder> {
    try {
        const res = await axiosGet<WorkOrder>(`/api/work-orders/${id}`)
        return decryptBody(res.data.data!!)
    } catch (error) {
        console.log(error)
        checkRedirect(error.response.status)
        throw error
    }
}

export async function ticketRunningNumber(type: string): Promise<string> {
    try {
        const params = {
            type: type
        }
        const res = await axiosGet<any>('/api/running-number', {params})
        return decryptBody(res.data.data!!)
    } catch (error) {
        console.log(error)
        checkRedirect(error.response.status)
        throw error
    }
}

export async function getAssignmentUser(): Promise<any> {
    try {
        const res = await axiosGet<AssignmentGroups[]>('/api/work-orders/assignmentGroups')
        return decryptBody(res.data.data!!)
    } catch (error) {
        console.log(error)
        checkRedirect(error.response.status)
        throw error
    }
}

export async function getSupportTeamUser(): Promise<any> {
    try {
        const res = await axiosGet<SupportTeamList[]>('/api/users/groups')
        return decryptBody(res.data.data!!)
    } catch (error) {
        console.log(error)
        checkRedirect(error.response.status)
        throw error
    }
}

export async function getAssignmentUserWithRole(role: string): Promise<any> {
    try {
        const res = await axiosGet<AssignmentGroups[]>('/api/work-orders/assignment/' + role)
        return decryptBody(res.data.data!!)
    } catch (error) {
        console.log(error)
        checkRedirect(error.response.status)
        throw error
    }
}

export async function getPredefinedInfo(incidentID: string): Promise<any> {
    try {
        const res = await axiosGet<predefinedInfo>('/api/work-orders/predefinedInfo/' + incidentID)
        return decryptBody(res.data.data!!)
    } catch (error) {
        console.log(error)
        checkRedirect(error.response.status)
        throw error
    }
}

// @ts-ignore
// export const getAllWorkOrders = (role: string, search?: string, filterStatus?: string) => {
//     return async (dispatch: Dispatch) => {
//         try {
//             const response = await axiosGet<WorkOrderList[]>(`/api/work-orders/search?role=${role}${search === null ? '' : '&search=' + search}${filterStatus === '' ? '' : '&filterStatus=' + filterStatus}&page=1&pageSize=10`)
//             // @ts-ignore
//             dispatch(GetAllWorkOrderSuccessEvent.build(decryptBody(response.data.data)))
//             return response.status
//         } catch (err) {
//             console.log(err)
//             checkRedirect(err.response.status)
//             throw err
//         }
//     }
// }

export const getAllWorkOrdersWithPagination = (page: number, pageSize: number, search?: string, filterStatus?: string,
    fieldName?: string, orderBy?: string, searchFieldValue?: MyFilter[]) => {
    return async (dispatch: Dispatch) => {
        try {
            const body = {
                search: search,
                filterStatus: filterStatus,
                page: page,
                pageSize: pageSize,
                fieldName: fieldName,
                orderBy: orderBy,
                searchFieldValue: searchFieldValue
            }
            const encryptData = encryptBody(JSON.stringify(body))
            const response = await axiosPostWithPagination<WorkOrderList[]>('/api/work-orders/search', encryptData, herderPostPatch)
            dispatch(GetAllWorkOrderSuccessEvent.build(decryptBody(response.data.data).content || []))
            dispatch(UpdateWorkOrderPageEvent.build(decryptBody(response.data.data).number + 1))
            dispatch(UpdateTotalContentWorkOrderEvent.build(decryptBody(response.data.data).totalElements || 0))
            return response.status
        } catch (err) {
            console.log(err)
            checkRedirect(err?.response?.status)
            throw err
        }
    }
}

export const addWorkOrder = (workOrder: WorkOrder) => {
    return async (dispatch: Dispatch) => {
        try {
            const encryptData = encryptBody(JSON.stringify(workOrder))
            const response = await axiosPost<WorkOrder>('/api/workorder', encryptData)
            console.log(decryptBody(response))
            // dispatch(AddWorkOrderSuccessEvent.build(response.data.data!!))
            return decryptBody(response.data)
        } catch (err) {
            console.log(err)
            checkRedirect(err?.response?.status)
            throw err
        }
    }
}

// del function (parameter id)
export const deleteworkOrder = (id: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosDelete<void>(`/api/work-orders/${id}`)
            dispatch(DeleteWorkOrderSuccessEvent.build(id))
            return response.status
        } catch (err) {
            console.log(err)
            checkRedirect(err?.response?.status)
            throw err
        }
    }
}

export const updateWorkOrder = (patch: WorkOrder) => {
    return async (dispatch: Dispatch) => {
        try {
            const id = patch.id!!
            delete patch.id
            const response = await axiosPatch<WorkOrder>(`/api/work-orders/update/${id}`, patch)
            dispatch(UpdateWorkOrderSuccessEvent.build(decryptBody(response.data.data!!)))
            return response.status
        } catch (err) {
            checkRedirect(err?.response?.status)
            throw err.response
        }
    }
}

// export const findWorkOrderId = async (woId: number) => {
//     try {
//         const res = await axiosGet<WorkOrder>(`/api/work-orders/findWorkOrderNumber/${woId}`)
//         return decryptBody(res.data.data!!) as WorkOrder
//     } catch (error) {
//         console.log(error)
//         checkRedirect(error.response.status)
//         throw error
//     }
// }

export const findWorkOrderIdOrLocation = async (value: string) => {
    try {
        const res = await axiosGet<WorkOrder[]>(`/api/work-orders/searchWOVTypePM/${value}`)
        return decryptBody(res.data.data!!) as WorkOrder[]
    } catch (error) {
        console.log(error)
        checkRedirect(error.response.status)
        throw error
    }
}

export const findCustomFieldWOByWorkflowID = async (id: string) => {
    try {
        const res = await axiosGet<FieldDetail[] | CustomFiled[]>(`/api/custom-fields/workflows/${id}`)
        return decryptBody(res.data.data!!) as FieldDetail[] | CustomFiled[]
    } catch (error) {
        console.log(error)
        checkRedirect(error.response.status)
        throw error
    }
}

export const getAllStatusWO = async () => {
    try {
        const res = await axiosGet<string[]>('/api/work-orders/statuses')
        return decryptBody(res.data.data!!) as string[]
    } catch (error) {
        console.log(error)
        checkRedirect(error.response.status)
        throw error
    }
}

// export const searchContactUser = async (searchText: string) => {
//     try {
//         const response = await axiosPost<any>(`/api/users/searchUser?search=${searchText}`)
//         return decryptBody(response.data.data!!)
//     } catch (err) {
//         console.log(err)
//         throw err
//     }
// }

export const createWorkOrder = async (workFlowId?: number, timeStamp?: string, serialNumberAssets?: string[], workOrder?: WorkOrder) => {
    try {
        const body = {
            workOrder: workOrder,
            workflowId: workFlowId,
            workflowTimestamp: timeStamp,
            serialNumberAssets: serialNumberAssets || []
        }
        const encryptData = encryptBody(JSON.stringify(body))
        const response = await axiosPost<WorkOrder>('/api/work-orders/workflow', encryptData, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const createWorkOrderNoWorkFlow = async (serialNumberAssets?: string[], workOrder?: WorkOrder) => {
    try {
        const body = {
            serialNumbers: serialNumberAssets || [],
            workOrder: workOrder
        }
        const encryptData = encryptBody(JSON.stringify(body))
        const response = await axiosPost<WorkOrder>('/api/work-orders/assets', encryptData, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const updateWO = async (id?: string, workFlow?: WorkOrder) => {
    try {
        const encryptData = encryptBody(JSON.stringify(workFlow))
        const response = await axiosPatch<WorkOrder>(`/api/work-orders/${id}`, encryptData, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const createAssetRelateWoView = async (woId?: string, serialNumberAssets?: string[]) => {
    try {
        const body = {
            woId: woId,
            serialNumberAssets: serialNumberAssets || []
        }
        const encryptData = encryptBody(JSON.stringify(body))
        const response = await axiosPost('/api/related-assets', encryptData, herderPostPatch)
        return decryptBody(response.data.data)
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const deleteAssetRelateWo = async (woId?: string, serialNumberAssets?: string) => {
    try {
        const response = await axiosDelete(`/api/related-assets/${woId}/${serialNumberAssets}`)
        return decryptBody(response.data.data)
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const getWoRelatedByIncidentId = async (IncidentId?: string) => {
    try {
        const response = await axiosGet(`/api/work-orders/related-tickets/${IncidentId}`)
        return decryptBody(response.data.data)
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const getWorkflowRelatedByIncidentId = async (IncidentId?: string) => {
    try {
        const response = await axiosGet(`/api/workflows/${IncidentId}`)
        return decryptBody(response.data.data)
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const UpdateWorkflowRelatedByIncidentId = async (IncidentId: string, dataWo: WorkOrder[]) => {
    try {
        const body = {
            workOrders: dataWo,
            workflowTimestamp: moment().format('YYYY-MM-DD HH:mm:ss')
        }
        const encryptData = encryptBody(JSON.stringify(body))
        const response = await axiosPost(`/api/work-orders/tickets/${IncidentId}/workflows`, encryptData, herderPostPatch)
        return decryptBody(response.data.data)
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const getHistoryWoRelatedAsset = async (serialNumber: string) => {
    try {
        const response = await axiosGet(`/api/related-assets/${serialNumber}`)
        return decryptBody(response.data.data!!)
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const getAllWorkOrderForApproveOrRejectJob = async (page: number, pageSize: number) => {
    try {
        const params = {
            page: page,
            size: pageSize
        }
        const response = await axiosGet<ApproveWorkOrder>('/api/jobs/awaiting-approval', {params})
        return decryptBody(response.data.data!!)
    } catch (err) {
        console.log(err)
        checkRedirect(err?.response?.status)
        throw err
    }
}

export const updateSignatureWO = async (idJobSign: string, dataSignatureDetail: jobSignatureDetail) => {
    try {
        const encryptData = encryptBody(JSON.stringify(dataSignatureDetail))
        const response = await axiosPatch(`/api/jobs/signature-details/${idJobSign}`, encryptData, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const getAllWorkOrderSignatureForm = async (woId?: string, jobNo?: string) => {
    try {
        const params = {
            id: woId,
            number: jobNo
        }
        const response = await axiosGet('/api/documents/job', {params})
        return decryptBody(response.data.data!!)
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const sentEmailRequesterWorkOrder = async (data: sentMailWorkOrder) => {
    try {
        const encryptData = encryptBody(JSON.stringify(data))
        const response = await axiosPost('/api/work-orders/send-mail', encryptData, herderPostPatch)
        return decryptBody(response.data.data)
    } catch (err) {
        console.log(err)
        throw err
    }
}
